#webchat-container {
  height: 100%;
  position: relative;
  width: 100%;
}

/* Hide the FAB */
.bpFab {
  display: none !important;
}

/* Make the chat window full width and height */
.bpWebchat {
  bottom: 0 !important;
  height: 100% !important;
  left: 0 !important;
  max-height: 100% !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  transform: none !important;
  width: 100% !important;
}

/* Remove media query styles for larger screens as we want full width on all devices */
@media (min-width: 768px) {
  .bpWebchat {
    bottom: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }
}

@media (max-width: 700px) {
  .bpWebchat {
    height: 90% !important;
    max-height: 90% !important;
  }

  /* Keep this to Prevent scrolling on the page when the chat is open on mobile */
  :has(.bpOpen) {
    overflow: hidden;
  }
}