@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'brandon_textbold';
  src: url('/fonts/brandon-text-bold-webfont.woff2') format('woff2'),
    url('/fonts/brandon-text-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_textbold';
  src: url('/fonts/brandon-text-bold-webfont.woff2') format('woff2'),
    url('/fonts/brandon-text-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'google-basic';
  src: url('/fonts/Basic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Black.eot');
  src: local('/fonts/Inter Black'), local('Inter-Black'),
    url('/fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Black.woff2') format('woff2'),
    url('/fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Bold.eot');
  src: local('/fonts/Inter Bold'), local('Inter-Bold'),
    url('/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Bold.woff2') format('woff2'),
    url('/fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-ExtraBold.eot');
  src: local('/fonts/Inter ExtraBold'), local('Inter-ExtraBold'),
    url('/fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('/fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Light.eot');
  src: local('/fonts/Inter Light'), local('Inter-Light'),
    url('/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Light.woff2') format('woff2'),
    url('/fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.eot');
  src: local('/fonts/Inter Regular'), local('Inter-Regular'),
    url('/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-ExtraLight.eot');
  src: local('/fonts/Inter ExtraLight'), local('Inter-ExtraLight'),
    url('/fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('/fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.eot');
  src: local('/fonts/Inter Medium'), local('Inter-Medium'),
    url('/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Medium.woff2') format('woff2'),
    url('/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.eot');
  src: local('/fonts/Inter SemiBold'), local('Inter-SemiBold'),
    url('/fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Thin.eot');
  src: local('/fonts/Inter Thin'), local('Inter-Thin'),
    url('/fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Inter-Thin.woff2') format('woff2'),
    url('/fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@layer utilities {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'brandon_textbold';
  }

  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
  }

  input,
  select {
    font-size: 100%;
  }

  #supplier-form input[type="text"]::placeholder,
  #supplier-form input[type="number"]::placeholder,
  #supplier-form input[type="tel"]::placeholder,
  #supplier-form input[type="email"]::placeholder {
    font-style: italic;
    font-weight: 400;
  }

  #supplier-form input::autofill,
  #supplier-form input:-webkit-autofill,
  #supplier-form input:-webkit-autofill:hover,
  #supplier-form input:-webkit-autofill:focus,
  #supplier-form input:-webkit-autofill:active {
    color: #717171;
    -webkit-text-fill-color: #717171;
  }

  #bookticket-form input[type="text"]::-webkit-input-placeholder,
  #bookticket-form input[type="tel"]::-webkit-input-placeholder,
  #bookticket-form input[type="email"]::-webkit-input-placeholder {
    font-weight: 300 !important;
    text-transform: capitalize;
  }

  #lead-capture select option,
  #lead-capture input[type="text"]::placeholder,
  #lead-capture input[type="number"]::placeholder,
  #lead-capture input[type="tel"]::placeholder,
  #lead-capture input[type="email"]::placeholder {
    font-style: italic;
    font-weight: 500;
  }

  #lead-capture input:-webkit-autofill,
  #lead-capture input:-webkit-autofill:hover,
  #lead-capture input:-webkit-autofill:focus,
  #lead-capture input:-webkit-autofill:active,
  #lead-capture textarea:-webkit-autofill,
  #lead-capture textarea:-webkit-autofill:hover,
  #lead-capture textarea:-webkit-autofill:focus,
  #lead-capture textarea:-webkit-autofill:active {
    -webkit-text-fill-color: #ffff;
  }

  #eventdatetime input[type="text"]::-webkit-input-placeholder,
  #eventdatetime input[type="text"]::-moz-placeholder,
  #eventdatetime input[type="text"]:-ms-input-placeholder,
  #eventdatetime input[type="text"]:-moz-placeholder {
    font-weight: 400;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #222222;
    background-color: transparent;
    font-weight: 500;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .scroll::-webkit-scrollbar {
    width: 0.1em;
  }

  .scroll::-webkit-scrollbar-tracks {
    box-shadow: inset 0 0 6px rgba(0, 200, 100, 0.3);
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: red;
    outline: 1px solid rosybrown;
  }

  body::-webkit-scrollbar {
    width: 0.25em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: #FF794D;
    outline: 1px solid #bc8f8f;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 8px;
    border-radius: 6px;
  }

  .custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background: #f6f6f7;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
    border-radius: 6px;
  }

  .hafla-header-img {
    height: 2rem !important;
  }

  .hafla-header-back {
    height: 1.25rem !important
  }

  .gallery-grid-main-container {
    margin-top: 2rem;
    padding: 0rem 4.5rem !important;
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -1.875rem;
    /* gutter size offset */
    margin-top: 2rem;
    width: auto;
  }

  .my-masonry-grid-column {
    padding-left: 1.875rem;
    /* gutter size */
    background-clip: padding-box;
  }

  .my-masonry-grid-column>div {
    background: grey;
    margin-bottom: 1.875rem;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #222222 !important;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #222222 !important;
  }

  .toasty-notification-add-to-cart {
    @apply h-13 w-full bg-black border border-black px-8 py-3 rounded-md relative;
  }

  .toasty-notification {
    @apply h-13 bg-black border border-black px-4 py-3 rounded-md relative;
  }

  .close-notification {
    @apply absolute top-0 right-0 pr-2 py-3 pt-1;
  }

  .close-notification {
    right: 26px;
  }

  .progress-bar {
    height: 3px;
    background: #04AA6D;
    width: 0%;
  }

  .sticky-header {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    animation: slide-down 0.7s;
  }

  .slide-down {
    animation: slide-down 0.7s;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }

    100% {
      opacity: 0.9;
      transform: translateY(0);
    }
  }

  .slide-up {
    animation: slide-up 0.7s;
  }

  @keyframes slide-up {
    0% {
      opacity: 0.9;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }


  .slide {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .quotes-radio input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #717171;
    border-radius: 50%;
    background-clip: content-box;
    padding: 1px;
  }

  .for-ios {
    min-width: 95%;
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }

  .about li::marker {
    font-size: 1.2em;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="email"]::placeholder {
    font-weight: 500;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .sc-dlfnuX.bVibPY.rec.rec-slider-container {
    margin: 0px auto;
  }

  .center-text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-height {
    height: inherit
  }

  .hr-collection {
    width: 100px;
    height: 5px;
    background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
    border-radius: 20px;
    /* #TODO: Important is not recommended */
    margin-top: 10px !important;
    /* #TODO: Important is not recommended */
    margin-bottom: 10px !important;
  }

  .header-icon-size {
    height: 36px;
    width: 36px
  }

  .search-icon-size {
    height: 30px;
    width: 30px;
  }

  #megamenu {
    background-color: rgba(0, 0, 0, .3);
    pointer-events: none;
    height: 100%;
    width: 100%;
  }

  .mega-menu {
    display: block;
    position: absolute;
    text-align: left;
    width: 100%;
    left: 0px;
  }

  .eventlist button:hover:after {
    content: "";
    height: 0px;
    width: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    position: absolute;
    bottom: 0px;
    top: 30px;
    left: 25%;
    z-index: 100;
  }

  #box:hover p {
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background: -webkit-linear-gradient(#F38023, #EE3D24);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .quotes-radio input[type="radio"]:checked {
    background-color: #717171;
    width: 18px;
    height: 18px;
  }

  .hr-center {
    width: 100px;
    margin: auto;
    height: 5px;
    background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
    border-radius: 20px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  #address-radio input[type="radio"]+label span {
    transition: background-color 0.5s ease-in-out;
    transform: .2s;
    transition-duration: 500ms;
  }

  #address-radio input[type="radio"]+label span:hover,
  #address-radio input[type="radio"]+label:hover span {
    transform: scale(1.2);
  }

  #address-radio input[type="radio"]:checked+label span {
    background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
    box-shadow: 0px 0px 0px 2px white inset;
  }

  .arrow-style {
    font-Size: 18px;
    padding-left: 12px;
  }

  .checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4cd964;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 68px;
    height: 68px;
    display: block;
    stroke-width: 4;
    background-color: #4cd964;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #4CD964;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  .react-datepicker-popper[data-placement^=top],
  .react-datepicker-popper {
    z-index: 999 !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  #otp input[type="tel"]:last-child {
    margin-right: 0px !important;
  }

  #yourlocation {
    overflow-x: scroll !important;
  }

  .border-gradient {
    background: linear-gradient(to right, #fff, #fff),
      linear-gradient(to bottom right, #F38023, #EE3D24);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  }

  .border-gradient-pink-bg {
    background: linear-gradient(to right, #FFF6FB, #FFF6FB),
      linear-gradient(to bottom right, #F38023, #EE3D24);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  }

  .policy-content p {
    padding-bottom: 10px;
  }

  .footer-padding-bottom {
    padding-bottom: 10px !important;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  #marketing-banner .carousel .control-dots {
    margin: 0px !important
  }

  #marketing-banner .slider-wrapper.axis-horizontal {
    margin-bottom: 20px;
  }

  .slide-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 86px !important;
    position: relative !important;
  }

  .scroll-animation {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
    }
  }

  #price input:checked,
  #priceonrequest input:checked,
  #instantprice input:checked,
  #discount-box input:checked,
  #eventrecommended input:checked {
    accent-color: #EA4842;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fade-out {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .shimmer::before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)100%);
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .sponser-animation,
  .sliderAnimation___300FY {
    transform: translateX(0);
    animation: scroll 40s linear infinite !important;
    animation-duration: 40s !important;
    animation-timing-function: linear !important;
    animation-delay: 0s !important;
    animation-iteration-count: infinite !important;
    animation-direction: normal !important;
    animation-fill-mode: none !important;
    animation-play-state: running !important;
    animation-name: scroll !important;
  }

  .product-card .carousel .control-dots .dot:hover {
    background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
  }

  .product-card .carousel .control-dots .dot {
    margin: 0px 4px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.26) !important;
    background-color: #E4E4E4;
    width: 6px;
    height: 6px;
  }

  .add-quantity-toasty-notification {
    height: auto;
    background: #FCF0EC;
    color: #222222;
    border: 1px solid #F3802333;
  }

  .add-quantity-toasty-notification::before,
  .add-quantity-toasty-notification::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .my-masonry-grid-column:nth-last-child(1) {
    padding-right: 0rem;
  }

  #modalticket {
    min-height: -webkit-fill-available;
  }

  .scroll-mt-23 {
    scroll-margin-top: 5.75rem;
  }

  .rounded-box {
    border-radius: 1em;
    border: 1px solid #ddd;
    box-shadow: -1px 2px 30px -8px rgb(0 0 0 / 10%)
  }

  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: .4
    }

    to {
      opacity: 1
    }
  }

  /* ALL MEDIA QUERY START FROM HERE */

  @media only screen and (min-width: 380px) {

    .partner-bg::before {
      background-size: contain;
    }

  }

  @media only screen and (min-width: 768px) {

    .scroll-mt-40 {
      scroll-margin-top: 10rem;
    }

    .mega-menu {
      position: relative;
    }

    .mega-menu::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 33.3333%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu::before {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 66.6666%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu>div::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 99.9999%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .product-card .carousel .control-dots {
      bottom: auto;
      margin: 0px;
      top: 170px;
    }


  }


  @media only screen and (min-width: 1024px) {

    .mega-menu {
      position: relative;
    }

    .mega-menu::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 25%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu::before {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 50%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu>div::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 75%;
      display: inline-block;
      top: 0;
      height: 100%;
    }


  }


  @media only screen and (min-width: 1280px) {

    .container {
      max-width: 1140px;
    }

    .mega-menu {
      position: relative;
    }

    .mega-menu::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 20%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu::before {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 40%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu>div::after {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 60%;
      display: inline-block;
      top: 0;
      height: 100%;
    }

    .mega-menu>div::before {
      content: "";
      width: 1px;
      border-right: 1px solid #F6F6F7;
      position: absolute;
      left: 80%;
      display: inline-block;
      top: 0;
      height: 100%;
    }


  }


  @media only screen and (min-width: 1380px) {

    .container {
      max-width: 1280px;
    }

  }


  @media only screen and (min-width: 1538px) {

    .container {
      max-width: 1440px;
    }

  }


  @media only screen and (max-width: 767px) {
    .partner-bg::before {
      content: '';
      background-repeat: no-repeat;
      display: block;
      z-index: 1;
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100%;
      left: 0;
    }

    .slide-inner {
      height: 60px !important;
    }

    .slide-inner-parent {
      margin: 0px 5px !important;
    }

    .custom-scroll::-webkit-scrollbar,
    .scroll-element::-webkit-scrollbar {
      width: 4px;
    }

    .whitespace-break {
      white-space: break-spaces;
    }

    .container {
      max-width: 100%;
    }

    .checkmark {
      width: 48px;
      height: 48px;
    }

    .hafla-header-img {
      height: 1rem !important;
    }

    .hafla-header-back {
      height: 0.875rem !important;
    }

    .gallery-grid-main-container {
      margin-top: 1.25rem;
      padding: 0rem 1.25rem !important;
    }

    .my-masonry-grid {
      margin: 0rem !important;
    }

    .my-masonry-grid-column {
      padding-left: 0rem;
      padding-right: 0.75rem;
    }

  }

  @media only screen and (max-width: 380px) {

    .partner-bg::before {
      left: 0px;
      background-size: cover;
    }

    #modalticket {
      min-height: -webkit-fill-available;
    }

  }

  @media only screen and (max-width: 300px) {

    .partner-bg::before {
      left: -20px;
    }

  }



  .z-index-negative {
    z-index: -1;
  }


}

.border-bottom-nero:after {
  content: "";
  display: block;
}

.border-bottom-brand:after {
  content: "";
  display: block;
  height: 3px;
  background-image: linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 50px;
  margin: 0px auto;
  border-radius: 50px;
}

@media (min-width:320px) and (max-width:640px) {
  .border-bottom-brand:after {
    width: 100% !important;
    height: 1px;
  }

  .border-bottom-nero:after {
    border-bottom: 1px solid #E4E4E4;
  }
}

.bg-cart {
  background: linear-gradient(90deg,
      #ef8023 0%,
      #d95799 54.02%,
      #ea3d24 100.04%);
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Style the check-mark/indicator */
.checkbox .check-mark:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.datepicker-input {
  padding-left: 31px !important;
}

.padding-left-none {
  padding-left: 0 !important;
}

.input-icon-top-spacing {
  top: 37px;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.gap-22 {
  gap: 22px;
}

@media only screen and (min-width: 1279px) {
  .cart-bubble {
    left: 45px !important;
    top: -1px !important;
  }
}

@media only screen and (max-width: 600px) {
  .cart-bubble {
    left: 23px !important;
    top: -1px !important;
  }
}

.radio-container {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-w16 .radiomark {
  width: 16px;
  height: 16px;
}

.radio-container.radio-w16 .radiomark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #E4E4E4;
  border-radius: 50%;
}

.radio-container input:checked~.radiomark {
  background-color: #EA4842;
}

.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radiomark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radiomark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: white;
}

.planner-cart-title-bg {
  background: #FDF1ED;
}

.loading-bg {
  background-color: rgba(34, 34, 34, 0.8);
}

.toaster {
  transform: translateX(-50%);
}

.excl-vat {
  top: 2.9rem
}

@media (min-width: 768px) {
  .excl-vat {
    top: 2.2rem !important;
  }
}

@media (min-width: 768px) {
  .md\:w-39\.375 {
    width: 39.375rem;
  }

  .md\:left-48 {
    left: 12rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:w-40 {
    width: 10rem;
  }
}

.left-44 {
  left: 11rem;
}

.-mt-20 {
  margin-top: -6.5rem;
}

.EXPIRED,
.cancelled {
  color: #717171;
}

.EXPIRED.item-detail img,
.cancelled.item-detail img {
  opacity: 0.6;
}

.EXPIRED.item-detail button,
.cancelled.item-detail button {
  background-color: #717171 !important;
  color: #fff !important;
}

.EXPIRED.item-detail input,
.EXPIRED.item-detail textarea {
  background-color: #F3F3F6 !important;
}

.item-detail .shadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.shadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

input[type="text"]:disabled,
select:disabled {
  background: #F3F3F6 !important;
}

.overflow-x {
  overflow-x: auto;
}


.new {
  color: #017AAD;
  background: #E5F5FC;
}

.in-progress {
  color: #E99400;
  background: #FDF4E5;
}

.submitted {
  color: #29823B;
  background: #EAF3EB;
}

.expired {
  color: #DC2020;
  background: #FCEAEA;
}

.black-overlayer {
  background: #00000063;
}

.planner-cart-gallery-box {
  width: 316px;
  height: 196px;
}

.planner-cart-gallery-box div {
  display: none;
}

.planner-cart-gallery-box:hover div {
  display: flex;
}

.payment-modal-bg-image-web {
  background-repeat: no-repeat;
  background-size: cover;
}

.payment-modal-overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #222;
  opacity: 0.8;
}

/* TODO: Avoid using !important */
@media (min-width:320px) and (max-width:768px) {
  .payment-modal-bg-image-mobile {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.hamburger-menu-item:hover .group-menu-icon {
  display: none;
}

.hamburger-menu-item:hover .group-menu-icon-hover {
  display: block;
}

.bg-pink-gradient {
  background-image: linear-gradient(63.67deg, #F684C8 8.28%, #FAC174 121.11%);
}

.event-progress-bar-content {
  background: rgba(252, 252, 252, 0.2)
}

.event-profile-progress-bar>div>div>div {
  background: linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%) !important;
  margin: 2px;
  height: 16px !important;
}

.event-profile-progress-bar>div>div {
  background-color: #fff !important;
}

.progress-bar-label {
  white-space: pre-wrap;
  word-break: break-word;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.toggle-slider {
  background-color: #EA4842;
}

input:focus+.toggle-slider {
  box-shadow: 0 0 1px #EA4842;
}

input:checked+.toggle-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}


.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

b,
strong {
  font-weight: bold;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.heba-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  min-height: 100vh;
  text-align: center;
  background: linear-gradient(66deg, rgba(229, 128, 35, 0.20) -33.51%, rgba(207, 87, 143, 0.20) 94.65%, rgba(224, 61, 36, 0.20) 207.46%);
}

.heba-loading-illustration {
  margin-bottom: 20px;
}

.heba-loading-animation {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #EA4842;
  mask: radial-gradient(circle closest-side at 50% 40%, #0000 94%, #000);
  transform-origin: 50% 40%;
  animation: heba-loading-animation-l25 1s infinite linear;
}

@keyframes heba-loading-animation-l25 {
  100% {
    transform: rotate(1turn)
  }
}

.heba-loading-text h2 {
  font-size: 1.8rem;
  font-family: Trebuchet MS;
  color: rgb(0, 0, 0);
  margin: 0.5em;
}

.heba-loading-text p {
  font-size: 1rem;
  font-family: Trebuchet MS;
  color: #999;
  margin: 0;
}